import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'app/utils/tw';
import {
  RiCheckboxCircleLine,
  RiCloseLine,
  RiInformationLine,
  RiLock2Line,
} from '@remixicon/react';
import { Button } from './button';

const alertVariants = cva('relative w-full text-sm py-2 px-4', {
  variants: {
    variant: {
      default: 'bg-homy-gray-lighter/10 text-homy-gray-light',
      info: 'bg-info-foreground text-info',
      warning: 'bg-warning-foreground text-warning',
      success: 'bg-success-foreground text-success',
      destructive: 'bg-destructive-foreground text-destructive',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const iconSwitch = (variant: VariantProps<typeof alertVariants>['variant']) => {
  switch (variant) {
    case 'default':
      return <RiLock2Line size={20} />;
    case 'info':
      return <RiInformationLine size={20} />;
    case 'warning':
      return <RiInformationLine size={20} />;
    case 'success':
      return <RiCheckboxCircleLine size={20} />;
    case 'destructive':
      return <RiInformationLine size={20} />;
    default:
      return <RiLock2Line size={20} />;
  }
};

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  canClose?: boolean;
}

const Alert = React.forwardRef<
  HTMLDivElement,
  AlertProps & VariantProps<typeof alertVariants>
>(({ className, variant, canClose, ...props }, ref) => {
  const [visible, setVisible] = React.useState(true);

  const removeElement = () => {
    setVisible((prev) => !prev);
  };

  return visible ? (
    <div
      ref={ref}
      role="alert"
      className={cn(
        alertVariants({ variant }),
        'flex items-center justify-between',
        className,
      )}
      {...props}
    >
      <div className="flex items-center gap-3">
        {iconSwitch(variant)}
        {props.children}
      </div>

      {canClose && (
        <Button
          variant="ghost"
          size="icon"
          onClick={removeElement}
          className="ml-2"
        >
          <RiCloseLine size={16} />
        </Button>
      )}
    </div>
  ) : null;
});
Alert.displayName = 'Alert';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('text-sm [&_p]:leading-relaxed', className)}
    {...props}
  />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertDescription };
